<template>
    <div class="authorization-page">

        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <h1>{{ $t("Reset Password") }}</h1>

            <form @submit.prevent="resetPassword" class="login-form">
                <div class="form-group">
                    <div v-if="errors[`token`]" v-for="error in errors[`token`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                    <input type="text" id="email" name="email" :placeholder="$t('Email')" v-model="credentials.email" class="form-control" required>
                    <div v-if="errors[`email`]" v-for="error in errors[`email`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <input v-model="credentials.password" type="password" class="form-control" :placeholder="$t('Password')" required>
                    <div v-if="errors[`password`]" v-for="error in errors[`password`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <input v-model="credentials.password_confirmation" type="password" class="form-control" :placeholder="$t('Repeat password')" required>
                </div>

                <button type="submit" class="btn btn-primary w-100" :value="$t('Login')" :disabled="disabled">{{ $t("Reset Password") }}</button>
            </form>

        </div>
    </div>
</template>

<script>
import {userService} from "@/services/userService";

export default {
    name: "ResetPassword",
    data() {
        return {
            credentials: {
                token: this.$route.query.token
            },


        }
    },
    methods: {
        resetPassword() {
            this.disabled = true
            this.errors = []

            userService.resetPassword(this.credentials)
                .then(() => this.$router.push({ name: 'Login' }))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    }
}
</script>

<style scoped>

</style>